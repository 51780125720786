<template>
    <page-content>
        <div style="font-size: 24px">此功能开发中...</div>

    </page-content>
</template>

<script>
    export default {
        name: "WorkOrder"
    }
</script>

<style scoped>

</style>
